import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import {SEO, ContentLayout, Page, Card, Grid} from '../../components';

export default function BlogIndex() {
	const data = useStaticQuery(graphql`
		query {
			background: file(relativePath: {eq: "blog.jpg"}) {
				childImageSharp {
					fluid(quality: 90, maxWidth: 1440) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			allMdx(
				sort: {fields: [frontmatter___date], order: DESC}
				filter: {frontmatter: {published: {eq: true}}}
			) {
				nodes {
					id
					fields {
						slug
					}
					frontmatter {
						description
						title
						date(formatString: "YYYY MMMM Do")
						featuredImage {
							childImageSharp {
								fluid(quality: 80, maxWidth: 800) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		}
	`);

	const imageData = data.background.childImageSharp.fluid;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const posts = data.allMdx.nodes.map(({id, fields, frontmatter}: any) => {
		const footer = (
			<p style={{textAlign: 'right', textDecoration: 'underline'}}>
				Read more...
			</p>
		);
		return (
			<Link key={id} to={fields.slug}>
				<Card
					fluidImage={frontmatter.featuredImage.childImageSharp.fluid}
					footer={footer}
				>
					<h3>{frontmatter.title}</h3>
					<h6>{frontmatter.date}</h6>
					<br />
					<p>{frontmatter.description}</p>
				</Card>
			</Link>
		);
	});

	const subTitle = (
		<>
			<p>
				This blog is home to a collection of guides, musings, and rambling short
				essays.
			</p>
			<p>I hope you enjoy.</p>
		</>
	);

	return (
		<>
			<SEO title="Blog" />
			<div id="portal-root" />
			<ContentLayout title="blog" titleLink="/blog">
				<Page title="Blog" subTitle={subTitle} heroImage={imageData}>
					<Grid padded>{posts}</Grid>
				</Page>
			</ContentLayout>
		</>
	);
}
